<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search
                :moduleapi="`${this.baseApi}/courier`"
                title="Courier"
                :columns="columns"
                routerpath="/hr/addcourierc"
                :formOptions="formOptions"
                :edit="edit"
                :add="add"
                :canDelete="canDelete"
                :viewFields="viewFields"
                :newPage="true"
              >
                <template #customField="{ props }">
                  <span v-if="props.column.field === 'date'">
                    {{
                      props.row.date && props.row.date != "0000-00-00"
                        ? moment(props.row.date).format("DD/MM/YYYY")
                        : "-"
                    }}
                  </span>
                </template>
              </good-table-column-search>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
import axios from "axios";
import moment from "moment";
const baseApi = process.env.VUE_APP_APIENDPOINT;
export default {
  components: {
    GoodTableColumnSearch,
  },
  data() {
    return {
      baseApi: process.env.VUE_APP_APIENDPOINT,
      moment,
      viewFields: [
        {
          label: "Date",
          field: "date",
          type: "date",
          class: "col-md-4",
        },
        {
          label: "From Name",
          field: "from_name",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "To Name",
          field: "to_name",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Courier Company",
          field: "courier_company",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Tracking No",
          field: "trackingno",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Pincode",
          field: "pincode",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "City",
          field: "city",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Area",
          field: "area",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "State",
          field: "state",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Remarks",
          field: "remarks",
          type: "text",
          class: "col-md-4",
        },
      ],
      columns: [
        {
          label: "Date",
          field: "date",
          type: "datetime",
          filterOptions: {
            enabled: true,
            placeholder: "Search date",
          },
        },
        {
          label: "Tracking No.",
          field: "trackingno",
          filterOptions: {
            enabled: true,
            placeholder: "Search Tracking No.",
          },
        },
        {
          label: "From Name",
          field: "from_name",
          filterOptions: {
            enabled: true,
            placeholder: "Search from Name",
          },
        },
        {
          label: "To Name",
          field: "to_name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Courier Company",
          field: "courier_company",
          filterOptions: {
            enabled: true,
            placeholder: "Search Courier Company",
          },
        },
        {
          label: "Pincode",
          field: "pincode",
          filterOptions: {
            enabled: true,
            placeholder: "Search Pincode",
          },
        },
        {
          label: "City",
          field: "city",
          filterOptions: {
            enabled: true,
            placeholder: "Search City",
          },
        },
        {
          label: "Area",
          field: "area",
          filterOptions: {
            enabled: true,
            placeholder: "Search Area",
          },
        },
        {
          label: "State",
          field: "state",
          filterOptions: {
            enabled: true,
            placeholder: "Search State",
          },
        },

        {
          label: "Enter By",
          field: "enterby",
          type: "staticdropdown",
          url: "getUsers",
          responseValue: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Enter Date",
          field: "created_at",
          type: "datetime",
          time: true,
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Modified By",
          field: "modifiedby",
          type: "staticdropdown",
          url: "getUsers",
          responseValue: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Modified Date",
          field: "updated_at",
          time: true,
          type: "datetime",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      formOptions: {
        formTitle: `${this.$route.params.id ? "Edit Courier" : "Add Courier"}`,
        submitRouterPath: "/hr/hrcourier",
        usersDropdown: true,
        branchDropdown: true,
        designationDropdown: true,
        method: "post",
        action: "add",
        url: `${baseApi}/courier`,
        getEditValue: `${baseApi}/courierById`,

        inputFields: [],
      },
      edit: "",
      add: "",
      canDelete: "",
    };
  },
  beforeMount() {
    this.getDropdowns();
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Request") {
          this.edit = item.edit;
          this.add = item.add;
          this.canDelete = item.delete;
          if (item.edit !== 1 && item.add !== 1 && item.delete !== 1 && item.view !== 1) {
            this.$router.push("/");
          }
        }
      });
    }
  },
  methods: {
    async getDropdowns() {
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      this.formOptions.inputFields.map(async (item) => {
        if (item.type == "dropdown" && item.url) {
          const user = {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            url: `${baseApi}/${item.url}`,
          };

          await axios(user)
            .then((response) => {
              response.data.data.data
                ? (item.options = response.data.data.data)
                : (item.options = response.data.data);

              // this.form[item.name] = data[item.name];
              // if (item.name == item.dropdownname) {
              //   item.options.map((user) => {
              //     if (user.id == this.form[item.dropdownname]) {
              //       item.item = user.name;
              //     }
              //   });
              // }
            })
            .catch((error) => console.log(error, "error"));
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
